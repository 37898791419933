import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { SideNav, Bio, Post, Footer, PostsContent, SmallNav } from '../';
import { usePosts } from './hooks';
import './content-container.css';

const ContentContainer = (): JSX.Element => {
  const globalPosts = usePosts();
  return (
    <div>
      <BrowserRouter>
        {/* Desktop */}
        <div className="mx-auto max-w-7xl mt-40 pb-20 hidden lg:block">
          <div className="flex">
            <div className="w-1/4">
              <SideNav />
            </div>
            <div className="w-3/4 pl-20 pr-20">
              <Switch>
                <Route exact path="/">
                  <Redirect from="/" to="/posts" />
                </Route>
                <Route
                  exact
                  path="/posts"
                  render={() => <PostsContent {...{ posts: globalPosts }} />}
                />
                <Route path="/posts/:slug" render={() => <Post />} />
                <Route path="/about" render={() => <Bio />} />
              </Switch>
            </div>
          </div>
        </div>

        {/* Mobile */}
        <div className="lg:hidden pb-20">
          <SmallNav />
          <div className="pt-20 pl-6 pr-6">
            <Switch>
              <Route exact path="/">
                <Redirect from="/" to="/posts" />
              </Route>
              <Route
                exact
                path="/posts"
                render={() => <PostsContent {...{ posts: globalPosts }} />}
              />
              <Route path="/posts/:slug" render={() => <Post />} />
              <Route path="/about" render={() => <Bio />} />
            </Switch>
          </div>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
};
export default ContentContainer;
