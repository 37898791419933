import React from 'react';
import { Copyright } from '@material-ui/icons';

const Footer = (): JSX.Element => {
  return (
    <div className="mt-8">
      <div
        className="w-full text-center bg-white bottom-0 p-2 fixed border-t"
        id="global-footer"
      >
        <footer>
          <Copyright /> 2021 brockwade.com
        </footer>
      </div>
    </div>
  );
};
export default Footer;
