import React from 'react';
import small_portrait from './test_black_n_white.jpeg';

const SmallPortrait = (): JSX.Element => {
  return (
    <img
      className="mx-auto hover:opacity-70"
      src={small_portrait}
      alt="Brock portrait"
    />
  );
};
export default SmallPortrait;
