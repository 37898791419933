import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SmallNav = (): JSX.Element => {
  return (
    <div>
      <Navbar expand="sm">
        <Navbar.Brand>
          <b>brock wade</b>
        </Navbar.Brand>
        <div className="text-xs">
          <a
            className="align-middle"
            href="https://www.github.com/brockwade633"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fa fa-github-square fa-2x text-black hover:opacity-70"></i>
          </a>
        </div>
        <div className="text-xs pl-1">
          <a
            href="https://www.linkedin.com/in/brock-wade"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fa fa-linkedin-square fa-2x text-black hover:opacity-70"></i>
          </a>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="text-black justify-content-end"
        >
          <Nav>
            <Link to="/about" className="hover:opacity-70 no-underline p-1">
              <b>about</b>
            </Link>
            <Link to="/posts" className="hover:opacity-70 no-underline p-1">
              <b>posts</b>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default SmallNav;
