import React from 'react';
import lumeris_logo from './lumerislogo.png';
import { Emoji } from '../';

const Bio = (): JSX.Element => {
  return (
    <div className="flex">
      <div className="mx-auto space-y-4">
        <p className="text-lg flex pb-4">
          <b className="mx-auto">###&nbsp;&nbsp;About Me&nbsp;&nbsp;###</b>
        </p>
        <p>
          - <b>software engineer</b> based in the <b>pacific northwest</b>&nbsp;
          <Emoji symbol="🌲" label="tree" />
        </p>
        <p>
          - currently <b>employed</b> at&nbsp;&nbsp;
          <a
            href="https://www.lumeris.com/"
            rel="noreferrer"
            target="_blank"
            className="hover:opacity-70"
          >
            <img
              src={lumeris_logo}
              alt="lumeris"
              className="inline-flex h-7 w-38"
            />
          </a>
        </p>
        <p>
          - interested in <b>mathematics,</b> <b>computer science,</b>{' '}
          <b>distributed systems,</b> <b>micro services,</b>{' '}
          <b>web applications</b>
        </p>
        <p>
          - fascinated by <b>natural language processing!</b>
        </p>
      </div>
    </div>
  );
};
export default Bio;
