import React from 'react';
import { EmojiProps } from '../../../models';

const Emoji = (props: EmojiProps): JSX.Element => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
  >
    {props.symbol}
  </span>
);
export default Emoji;
