import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PostFull } from '../../../models';
const apigUrl = `${process.env.REACT_APP_API_URL}`;

export const useBlogPost = (): PostFull | null => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState<PostFull | null>(null);

  const retrieveBlogPost = async () => {
    const response = await fetch(`${apigUrl}/posts/${slug}`);
    const post = await response.json();
    setBlogPost(post[0]);
  };

  useEffect(() => {
    retrieveBlogPost();
    // eslint-disable-next-line
  }, [slug]);
  return blogPost;
};
