import React from 'react';
import { Link } from 'react-router-dom';
import { PostPartial } from '../../../../models';

const FeaturedPost = (post: PostPartial): JSX.Element => {
  return (
    <div className="space-y-4 max-w-md">
      <div className="text-lg flex">
        <p className="mx-auto">
          <b>###&nbsp;&nbsp;Featured&nbsp;&nbsp;###</b>
        </p>
      </div>
      <div className="p-4 border border-white rounded-md hover:border-black">
        <Link
          to={`/posts/${post.slug.current}`}
          className="no-underline text-black"
        >
          <div>
            <div className="text-2xl flex">
              <p className="mx-auto">
                <b>{post.title}</b>
              </p>
            </div>
            <div className="flex pb-4">
              <p className="mx-auto">
                {new Date(post.publishDate).toDateString().slice(4)}
              </p>
            </div>
            <img
              className="w-40 h-40 mx-auto"
              src={post.thumbnail.asset.url}
              alt="Brock portrait"
            />
            <div className="pt-4">
              <p>{post.summary}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default FeaturedPost;
