import React from 'react';
import { SmallPortrait } from './';
import { Link } from 'react-router-dom';

const SideNav = (): JSX.Element => {
  return (
    <div className="text-center sticky top-40">
      <div className="inline-flex">
        <div>
          <Link to={'/about'}>
            <SmallPortrait />
          </Link>
        </div>
        <div className="text-left p-2 pt-12">
          <div className="text-2xl">
            <b>brock wade</b>
          </div>
          <div className="pt-2 space-y-0.5">
            <div>
              <Link
                to={'/about'}
                className="no-underline hover:opacity-70 text-black"
              >
                <b>about</b>
              </Link>
            </div>
            <div>
              <Link
                to={'/posts'}
                className="no-underline hover:opacity-70 text-black"
              >
                <b>posts</b>
              </Link>
            </div>
            <div className="inline-flex pt-1 items-center text-xs">
              <div className="pr-0.5">
                <a
                  href="https://www.github.com/brockwade633"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-github-square fa-2x text-black hover:opacity-70"></i>
                </a>
              </div>
              <div className="pl-0.5">
                <a
                  href="https://www.linkedin.com/in/brock-wade"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-linkedin-square fa-2x text-black hover:opacity-70"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideNav;
