import React from 'react';
import { Link } from 'react-router-dom';
import { PostPartial } from '../../../../../models';

const PostListItem = (post: PostPartial): JSX.Element => {
  return (
    <div className="group">
      <Link
        to={`/posts/${post.slug.current}`}
        className="no-underline text-black"
      >
        <div className="flex p-1 border-white border rounded-md group-hover:border-black">
          <img
            className="w-10 h-10"
            src={post.thumbnail.asset.url}
            alt="Brock portrait"
          />
          <div className="pl-1">
            <p>{post.title}</p>
            <p className="text-xs font-bold">
              {new Date(post.publishDate).toDateString().slice(4)}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default PostListItem;
