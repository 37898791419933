import { useState, useEffect } from 'react';
import { PostPartial } from '../../../models';
const apigUrl = `${process.env.REACT_APP_API_URL}`;

export const usePosts = (take?: number): PostPartial[] | null => {
  const [posts, setPosts] = useState<PostPartial[] | null>(null);

  const retrievePosts = async () => {
    const takeStr = take ? `?take=${take}` : ``;
    const response = await fetch(`${apigUrl}/posts${takeStr}`);
    const posts = await response.json();
    setPosts(posts);
  };

  useEffect(() => {
    retrievePosts();
  }, []);
  return posts;
};
