import React from 'react';
import ReactMarkdown from 'react-markdown';
import { CodeComponent } from 'react-markdown/lib/ast-to-react';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useBlogPost } from '../../';
import { CircularProgress } from '@material-ui/core';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { foundation } from 'react-syntax-highlighter/dist/esm/styles/hljs/';

const components = {
  // eslint-disable-next-line react/prop-types
  code({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    node,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inline,
    className,
    children,
    ...props
  }: Parameters<CodeComponent>[0]) {
    const match = /language-(\w+)/.exec(className || '');
    if (match) {
      const language = match[1];
      const { showLineNumbers, codeStyle, parsedChildren } =
        language === 'bash'
          ? {
              showLineNumbers: false,
              codeStyle: foundation,
              parsedChildren: String(children).replace(/\n$/, ''),
            }
          : {
              showLineNumbers: true,
              codeStyle: vscDarkPlus,
              parsedChildren: children,
            };
      return (
        <SyntaxHighlighter
          // eslint-disable-next-line react/no-children-prop
          children={parsedChildren}
          style={codeStyle}
          language={language}
          showLineNumbers={showLineNumbers}
          {...props}
        />
      );
    } else {
      return (
        <code className={className} {...props}>
          {children}
        </code>
      );
    }
  },
};

const Post = (): JSX.Element => {
  const post = useBlogPost();
  if (!post)
    return (
      <div className="text-center">
        <CircularProgress className="text-black" />
      </div>
    );

  return (
    <div className="bg-white overflow-auto w-full pb-10 pl-4 pr-4">
      <div className="text-center">
        <div className="p-2">
          <p className="text-3xl md:text-4xl">
            <b id="post-title">{post.title}</b>
          </p>
        </div>
        <div className="text-xs md:text-sm space-y-3">
          <div className="inline-flex">
            <p className="border-r pr-2">
              <b id="post-author">{post.name}</b>
            </p>
            <p id="post-publish-date" className="pl-2 pr-2">
              {new Date(post.publishDate).toDateString().slice(4)}
            </p>
          </div>
          <div id="post-categories" className="flex text-tiny md:text-xs">
            <div className="inline-flex mx-auto flex-wrap">
              <div className="p-1">
                &nbsp;&nbsp;&nbsp;
                <b className="border border-black p-0.5 rounded-md">
                  {post.categories[0]}
                </b>
              </div>
              {post.categories &&
                post.categories.slice(1).map((category) => (
                  <div className="p-1" key={category}>
                    &nbsp;&nbsp;&nbsp;
                    <b className="border border-black p-0.5 rounded-md">
                      {category}
                    </b>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div id="post-image" className="p-8">
        <img
          className="block mx-auto w-52"
          src={post.mainImage.asset.url}
          alt="blog-image"
        />
      </div>
      <div id="post-body" className="prose-sm md:prose-lg p-4 pb-8">
        <ReactMarkdown
          // eslint-disable-next-line react/no-children-prop
          children={post.body}
          components={components}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    </div>
  );
};
export default Post;
