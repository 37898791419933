import React from 'react';
import { PostsProps } from '../../../../models';
import { CircularProgress } from '@material-ui/core';
import { PostListItem } from './';

const PostList = (props: PostsProps): JSX.Element => {
  const posts = props.posts;
  if (!posts)
    return (
      <div className="text-center">
        <CircularProgress className="text-black" />
      </div>
    );
  return (
    <div>
      <div className="space-y-6">
        <div className="text-lg flex">
          <p className="mx-auto">
            <b>###&nbsp;&nbsp;All Posts&nbsp;&nbsp;###</b>
          </p>
        </div>
        <div className="flex">
          <div className="mx-auto space-y-2">
            {posts &&
              posts.map((post) => <PostListItem key={post.title} {...post} />)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PostList;
