import React from 'react';
import { PostList, FeaturedPost } from '../../';
import { PostsProps } from 'models';

const PostsContent = (props: PostsProps): JSX.Element => {
  const postsList = props.posts;
  return (
    <div>
      {/* Desktop */}
      <div className="hidden md:block">
        <div className="flex space-x-6">
          <div className="w-1/2">
            <PostList {...{ posts: postsList }} />
          </div>
          <div className="w-1/2">
            {postsList && <FeaturedPost {...postsList[0]} />}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex md:hidden">
        <div className="mx-auto">
          <PostList {...{ posts: postsList }} />
        </div>
      </div>
    </div>
  );
};
export default PostsContent;
